@font-face {
  font-family: 'Suplexmentary Comic NC';
  src: url('./Suplexmentary\ Comic\ NC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* Global Styles */
body {
  margin: 0;
  font-family: Suplexmentary Comic NC, sans-serif;
  background-color: #f5f5f5;
}

/* Header Styles */
header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

header h1 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
}

/* Main Part Styles */
main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  padding: 20px;
}

main img {
  max-width: 100%;
  max-height: 500px;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons img {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  filter: invert(1); /* Invert the colors for better visibility on dark background */
}